* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    font-family: Inter;
}
 
 *,
 *:before,
 *:after {
   box-sizing: border-box;
 }
 
 :focus,
 :active {
   outline: none;
 }
 
 a:focus,
 a:active {
   outline: none;
 }
 
 nav,
 footer,
 header,
 aside {
   display: block;
 }
 
 html,
 body {
   height: 100%;
   width: 100%;
   font-size: 100%;
   line-height: 1;
   font-size: 14px;
   -ms-text-size-adjust: 100%;
   -moz-text-size-adjust: 100%;
   -webkit-text-size-adjust: 100%;
 }
 
 input,
 button,
 textarea {
   font-family: inherit;
 }
 
 input::-ms-clear {
   display: none;
 }
 
 button {
   cursor: pointer;
   background: none;
   border: none;
   padding: 0;
   margin: 0;
   text-decoration: none;
 }
 
 button::-moz-focus-inner {
   padding: 0;
   border: 0;
 }
 
 a,
 a:visited {
   text-decoration: none;
 }
 
 a:hover {
   text-decoration: none;
 }
 
 ul li {
   list-style: none;
 }
 
 img {
   vertical-align: top;
 }
 
 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
   font-size: inherit;
   font-weight: 400;
 }

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #EAEAEA;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(156, 155, 155);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
   background: #A78BE2;
}