
.calendar-container {
   border-radius: 0px;
   border: none;
   border-top: 1px solid #cacaca;
   width: 280px;

   @media (max-width: 1024px) {
     width: max-content;    
   }
 }

.calendar-container-left {
   border-right: 1px solid #cacaca;
 }

.react-datepicker__month-container {
   border-radius: 0px;
   width: 100%;
 }

.react-datepicker__header {
   padding-top: 12px;
   background:#fff;
   border-bottom: none;
 }

 .react-datepicker__navigation-icon::before {
   border-color: #3461D6;
   border-width: 2px 2px 0 0;
   top: 11px;
 }

 .react-datepicker__current-month {
   height: 25px;
   margin-bottom: 3px;
   background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
   background-clip: text;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-family: Roboto;
   font-size: 14px;
   font-weight: 700;
   line-height: normal;
   text-transform: capitalize;
  }
 
  .react-datepicker__month {
   margin-top: 2px;
  }

 .react-datepicker__day-names {
  border-top: 1px solid #cacaca;
  display: flex;
  padding: 0 18px;
}

 .calendar-week-days-container {
   display: flex;
   justify-content: space-around;
   align-items: flex-end;
   padding-top: 6px;
   width: 100%;
   background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
   background-clip: text;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-family: Roboto;
   font-size: 12px;
   font-weight: 600;
   line-height: normal;
   text-transform: capitalize;
   

   @media (max-width: 1024px) {
      font-size: 14px;
   }
 }
 
 .calendar-days-container {
   display: flex;
   flex-wrap: wrap;
   gap: 6px;
   padding: 8px 0 17px;
   margin: 0 auto;
 }
 
 .calendar-day {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   width: 30px;
   height: 30px;
   flex-direction: column;
   border-radius: 4px;
   background: #cacaca;
   color: #fff;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   border: 2px solid transparent;
   cursor: pointer;

   @media (max-width: 1024px) {
      font-size: 16px;
      width: 36px;
      height: 36px;
    }
 }
 
 .calendar-day:hover {
   background: #cacaca;
   box-shadow: 2.732px 2.732px 5.281px 0px rgba(0, 0, 0, 0.14);
   transition: all 0.2s;
 }

 .react-datepicker__day--in-range {
   background: linear-gradient(180deg, #5172EA -18.38%, #7A5AEE 108.82%);
 }

 .react-datepicker__day--in-range:hover {
   background: linear-gradient(180deg, #5172EA -18.38%, #7A5AEE 108.82%);
 }

 .react-datepicker__day--selected {
   background: linear-gradient(180deg, #5172EA -18.38%, #7A5AEE 108.82%);
 }

 .react-datepicker__day--selected:hover {
   background: linear-gradient(180deg, #5172EA -18.38%, #7A5AEE 108.82%);
 }

 .react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
   color: #fff;
   opacity: 0.6;
 }

 .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
   background-color: #cacaca;
 }

 .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,.react-datepicker__month-text--in-range,.react-datepicker__quarter-text--in-range,.react-datepicker__year-text--in-range) {
   background: linear-gradient(180deg, #5172EA -18.38%, #7A5AEE 108.82%);
   opacity: 0.5;
 }

 .react-datepicker__day--disabled {
   opacity: 0.5;
   cursor: default;
 }



 